import CodeMirror from 'codemirror'
import type {Editor} from 'codemirror'

const assetsRel = document.querySelector('link[rel=assets]')
const assetHostUrl = (assetsRel instanceof HTMLLinkElement && assetsRel.href) || '/'
const modeURL = `${assetHostUrl}static/javascripts/codemirror%CONTRIB/mode/%N/%N.js?v=1`
const loading: {[key: string]: Callback[]} = {}

type Callback = () => void

function splitCallback(cont: Callback, n: number): Callback {
  let countDown = n
  return () => {
    if (--countDown === 0) {
      cont()
    }
  }
}

function ensureDeps(mode: string, cont: Callback) {
  // @ts-expect-error dependencies doesn't exist on mode?
  const deps = CodeMirror.modes[mode].dependencies
  if (!deps) {
    return cont()
  }

  const missing = []
  for (let i = 0; i < deps.length; ++i) {
    if (!CodeMirror.modes.hasOwnProperty(deps[i])) {
      missing.push(deps[i])
    }
  }
  if (!missing.length) {
    return cont()
  }

  const split = splitCallback(cont, missing.length)
  for (let i = 0; i < missing.length; ++i) {
    CodeMirror.requireMode(missing[i], split)
  }
}

export function requireMode(mode: string, cont: Callback) {
  if (CodeMirror.modes.hasOwnProperty(mode)) {
    return ensureDeps(mode, cont)
  }
  if (loading.hasOwnProperty(mode)) {
    return loading[mode]!.push(cont)
  }

  const modeObj = CodeMirror.findModeByName(mode)
  // @ts-expect-error contrib is not a value on modeObj
  const contrib = modeObj && modeObj.contrib ? '/contrib' : ''
  const file = modeURL.replace(/%CONTRIB/, contrib).replace(/%N/g, mode)

  // XXX: Export CodeMirror global for lazy load dep to attach to
  window.CodeMirror = CodeMirror

  // eslint-disable-next-line github/no-dynamic-script-tag
  const script = document.createElement('script')
  script.src = file

  script.crossOrigin = 'anonymous'
  const metaElement = document.querySelector('meta[name=codemirror-crossorigin-credentials]')
  if (metaElement) {
    script.crossOrigin = 'use-credentials'
  }

  const others = document.getElementsByTagName('script')[0]!
  const list = (loading[mode] = [cont])
  CodeMirror.on(script, 'load', () => {
    ensureDeps(mode, () => {
      for (let i = 0; i < list.length; ++i) {
        list[i]!()
      }
    })
  })

  if (others.parentNode == null) {
    throw new Error('first script node must be in the document.')
  }

  others.parentNode.insertBefore(script, others)
}

export function autoLoadMode(instance: Editor, mode: string) {
  if (!CodeMirror.modes.hasOwnProperty(mode)) {
    CodeMirror.requireMode(mode, () => {
      instance.setOption('mode', instance.getOption('mode'))
    })
  }
}
