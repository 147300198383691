// eslint-disable-next-line no-restricted-imports
import {fire, on} from 'delegated-events'
import {onInput, onKey} from '@github-ui/onfocus'
import {debounce} from '@github/mini-throttle'
import {fillFormValues} from '@github-ui/form-utils'
import {getAsyncCodeEditor, getCodeEditor} from '../code-editor'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {parseHTML} from '@github-ui/parse-html'
import {controller, attr} from '@github/catalyst'
import {CodeEditorExtensions as SecretScanningPushProtection} from '../secret-scanning/push-protection'

const PREVIEW_STATES = ['loading-preview', 'show-preview', 'no-changes-preview', 'error-preview']
const EDITOR_STATES = ['show-code'].concat(PREVIEW_STATES)

interface BlobEditorTabChangeEvent {
  name: BlobEditorTabName
}

type BlobEditorTabName = 'show-code' | 'preview' | 'template-editor'

// Catalyst element for the blob/gist content editor
@controller
class BlobEditorElement extends HTMLElement {
  static attrPrefix = ''
  @attr discoveredSecrets: string

  async connectedCallback() {
    if (this.discoveredSecrets) {
      const codeEditor = await getAsyncCodeEditor(this)
      SecretScanningPushProtection.annotateDiscoveredSecrets(codeEditor.editor!, this.discoveredSecrets)
    }
  }
}

function currentTab(editor: HTMLElement): BlobEditorTabName {
  if (editor.classList.contains('show-code')) {
    return 'show-code'
  } else if (editor.classList.contains('template-editor')) {
    return 'template-editor'
  } else {
    return 'preview'
  }
}

// Adds the `selected` class name to the appropriate tab
function selectCurrentTab(editor: HTMLElement) {
  const tabName = currentTab(editor)

  for (const tab of editor.querySelectorAll('.js-blob-edit-tab')) {
    const selected = tab.getAttribute('data-tab') === tabName
    tab.classList.toggle('selected', selected)
    if (selected) {
      tab.setAttribute('aria-current', 'true')
    } else {
      tab.removeAttribute('aria-current')
    }
  }

  const event: BlobEditorTabChangeEvent = {name: tabName}
  fire(document.querySelector<HTMLElement>('.js-file-editor-nav')!, 'tab:change', event)
}

function isMarkdown(filename: string): boolean {
  const mdExtensions = ['.md', '.mkdn', '.mkd', '.mdown', '.markdown']
  for (const ext of mdExtensions) {
    if (filename.endsWith(ext)) {
      return true
    }
  }
  return false
}

function isGist(editor: HTMLElement): boolean {
  return editor.getAttribute('data-is-gist') === 'true'
}

function getFilenameInput(editor: HTMLElement, form: HTMLElement): HTMLInputElement {
  return (isGist(editor) ? editor : form).querySelector<HTMLInputElement>('.js-blob-filename')!
}

function setFilenameEditable(editor: HTMLElement, form: HTMLElement, editable: boolean) {
  const filename = getFilenameInput(editor, form)
  if (isGist(editor)) {
    filename.readOnly = !editable
  } else {
    filename.disabled = !editable
  }

  filename.style.cursor = editable ? 'auto' : 'not-allowed'
}

// The "enter" key submits a form by default. We don't want that to happen
// in the filename field.
onKey('keypress', 'input.js-blob-filename', function (event: KeyboardEvent) {
  // TODO: Refactor to use data-hotkey
  /* eslint eslint-comments/no-use: off */
  /* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */
  if (event.key === 'Enter') {
    const form = (event.target as HTMLInputElement).form!
    const nextField = form.querySelector<HTMLElement>('select.js-code-indent-mode')
    if (nextField) nextField.focus()
    event.preventDefault()
  }
  /* eslint-enable @github-ui/ui-commands/no-manual-shortcut-logic */
})

function setPreviewTabVisibility(filenameInput: HTMLInputElement) {
  const header = filenameInput.closest('.file-header')!
  const tabs = header.querySelector<HTMLElement>('.js-file-editor-nav')!

  tabs.hidden = !isMarkdown(filenameInput.value)
}

// For Gists, we want to only show the preview tab when the filename is a
// Markdown file
onKey('keyup', '.js-code-editor[data-is-gist] input.js-blob-filename', function (event: KeyboardEvent) {
  const target = event.target as HTMLInputElement
  setPreviewTabVisibility(target)
})

// set initial visibility of branch name label
observe('.js-branch-name-label-container', setBranchNameLabel)

// set initial visibility of preview tab
observe('.js-gist-filename', {
  add(el) {
    setPreviewTabVisibility(el as HTMLInputElement)
  },
})

//
// preview your commit
//
function activateEditTab(event: Event) {
  const editor = (event.currentTarget as Element).closest<HTMLElement>('.js-code-editor')!
  if (editor.classList.contains('show-code')) {
    return
  }

  editor.querySelector<HTMLElement>('.js-blob-edit-code')!.removeAttribute('data-hotkey')

  setState(editor, 'show-code')
  selectCurrentTab(editor)

  const codeEditor = getCodeEditor(editor)
  if (codeEditor) {
    codeEditor.refresh()
    codeEditor.focus()
  } else if (editor.classList.contains('js-mobile-code-editor')) {
    editor.querySelector<HTMLElement>('.js-code-textarea')!.focus()
  }

  const form = editor.closest<HTMLElement>('.js-blob-form')!
  setFilenameEditable(editor, form, true)
}

function setBranchNameLabel() {
  const branchNameContainer = document.querySelector('.js-branch-name-label-container') as HTMLElement
  const creatingBranch = !!document.querySelector('.js-quick-pull-choice-option[value=quick-pull]:checked')

  if (branchNameContainer) {
    if (creatingBranch) {
      branchNameContainer.hidden = true
    } else {
      branchNameContainer.hidden = false
    }
  }
}

function showSimpleDiff(event: Event, editor: HTMLElement): boolean {
  const toggle = editor.querySelector('.js-preview-diff-toggle')
  if (toggle && toggle instanceof HTMLInputElement) {
    return !toggle.checked
  } else {
    return isSimpleDiffFeatureEnabled(editor)
  }
}

function isSimpleDiffFeatureEnabled(editor: HTMLElement): boolean {
  return editor.getAttribute('data-simplediff-enabled') === 'true'
}

function activatePreviewTab(event: Event) {
  const editor = (event.currentTarget as Element).closest<HTMLElement>('.js-code-editor')!
  if (!editor.classList.contains('show-code') && !editor.classList.contains('template-editor')) {
    return
  }

  editor.querySelector<HTMLElement>('.js-blob-edit-code')!.setAttribute('data-hotkey', 'Mod+Shift+P')

  const blobForm = editor.closest<HTMLFormElement>('.js-blob-form')!
  return showPreview(editor, blobForm, !isGist(editor), !isGist(editor) && showSimpleDiff(event, editor))
}

function toggleDiffPreview(event: Event) {
  const element = event.currentTarget
  if (element instanceof HTMLInputElement) {
    const showDiffPreview = element.checked

    const editor = (event.currentTarget as Element).closest<HTMLElement>('.js-code-editor')!
    const blobForm = editor.closest<HTMLFormElement>('.js-blob-form')!
    return showPreview(editor, blobForm, true, !showDiffPreview)
  }
}

on('click', '.js-blob-edit-code', activateEditTab)
on('click', '.js-blob-edit-preview', activatePreviewTab)
on('click', '.js-preview-diff-toggle', toggleDiffPreview)

on('codeEditor:preview', '.js-code-textarea', function (event) {
  const editor = event.currentTarget.closest<HTMLElement>('.js-code-editor')!
  if (editor.classList.contains('show-code')) {
    activatePreviewTab(event)
  }
})

function setState(editor: HTMLElement, className: string) {
  editor.classList.remove(...EDITOR_STATES)
  editor.classList.add(className)

  const actions = editor.querySelector('.preview-actions')
  if (actions instanceof HTMLElement) {
    actions.hidden = !PREVIEW_STATES.includes(className)
  }

  const editorElement = editor.closest('.js-code-editor')
  const banner: HTMLElement | null = editorElement!.querySelector('.focus-trap-banner')

  if (banner && className === 'show-code') {
    banner.hidden = false
  } else if (banner && PREVIEW_STATES.includes(className)) {
    banner.hidden = true
  }
}

let previousController: AbortController | null = null

async function showPreview(
  editor: HTMLElement,
  blobForm: HTMLFormElement,
  checkConflict: boolean,
  simplePreview: boolean,
) {
  setState(editor, 'loading-preview')
  selectCurrentTab(editor)

  setFilenameEditable(editor, blobForm, false)

  const willcreatebranch = !!document.querySelector('.js-quick-pull-choice-option[value=quick-pull]:checked')
  const previewForm = document.querySelector<HTMLFormElement>('.js-blob-preview-form')!
  const codeEditor = getCodeEditor(editor)
  let code = null
  // should be 1-indexed, not 0
  let line = 1
  if (codeEditor != null) {
    code = codeEditor.code()
    line = codeEditor.editor!.getCursor('from').line + 1
  } else if (editor.classList.contains('js-mobile-code-editor')) {
    const textarea = editor.querySelector<HTMLTextAreaElement>('.js-code-textarea')!
    code = textarea.value
    line = code.substring(0, textarea.selectionStart).split(/\r?\n/).length
  } else {
    return
  }

  if (isGist(editor)) {
    fillFormValues(previewForm, {
      blobname: editor.querySelector<HTMLInputElement>('.js-blob-filename')!.value,
      code,
    })
  } else {
    fillFormValues(previewForm, {
      code,
      commit: blobForm.querySelector<HTMLInputElement>('.js-commit-oid')!.value,
      blobname: blobForm.querySelector<HTMLInputElement>('.js-blob-filename')!.value,
      willcreatebranch: willcreatebranch.toString(),
      checkConflict: checkConflict.toString(),
    })
  }

  previousController?.abort()
  const {signal} = (previousController = new AbortController())
  try {
    const url = new URL(previewForm.action, window.location.origin)
    if (simplePreview) {
      url.searchParams.append('avoiddiff', 'true')
    }

    const response = await fetch(url.toString(), {
      method: previewForm.method,
      body: new FormData(previewForm),
      signal,
    })
    if (!response.ok) throw new Error('network error')
    const data = await response.text()

    if (!editor.classList.contains('loading-preview')) {
      return
    }

    const html = parseHTML(document, data)
    let preview = isGist(editor) ? html : html.querySelector('.data.highlight')
    if (!preview) {
      preview = html.querySelector('#readme')
    }
    if (!preview) {
      preview = html.querySelector('.js-preview-new-file')
    }
    if (!preview) {
      preview = html.querySelector('.js-preview-msg')
    }
    if (!preview && (preview = html.querySelector('.render-container'))) {
      preview.classList.add('is-render-requested')
    }

    if (preview) {
      const commitPreview = editor.querySelector<HTMLElement>('.js-commit-preview')!
      commitPreview.textContent = ''
      commitPreview.appendChild(preview)
      setState(editor, 'show-preview')
      scrollToEditPosition(commitPreview, line)
    } else {
      setState(editor, 'no-changes-preview')
    }
  } catch (error) {
    if (editor) {
      setState(editor, 'error-preview')
    }
  }
}

function scrollToEditPosition(commitPreview: HTMLElement, line: number) {
  // only support blobs that went through the Markdown renderer
  if (!commitPreview.querySelector('.markdown-body') || !commitPreview.querySelector('[data-sourcepos]')) {
    return
  }

  let scrollTo: HTMLElement | null = null
  // this is a string match, and the sourcepos range may be something like '1:1-27:34'. if the user's cursor is
  // on line 26, then no attribute will match. this tries to find a match anyway by checking up to 50 lines above the
  // cursor line before giving up. (searches up instead of closest in either direction because going down would result
  // in the cursor line being off the top of the screen, whereas 50 lines above will probably put the cursor line on
  // the screen at least.)
  for (let i = line; i >= line - 50; i--) {
    scrollTo = commitPreview.querySelector(`[data-sourcepos^="${i}:"]`)
    if (scrollTo) {
      break
    }
  }

  scrollTo?.scrollIntoView({block: 'start'})
}

//
// Quick-pull commit form stuff
//
on('change', '.js-quick-pull-choice-option', function (event) {
  const creatingBranch = (event.currentTarget as HTMLInputElement).value === 'quick-pull'
  const targetBranchParam = document.querySelector<HTMLInputElement>('.js-quick-pull-target-branch')!
  const quickPullParam = document.querySelector<HTMLInputElement>('.js-quick-pull-choice-value')!
  const submitButton = document.querySelector<HTMLElement>('.js-blob-submit')!

  setBranchNameLabel()

  if (creatingBranch) {
    const newBranchNameField = document.querySelector<HTMLInputElement>('.js-quick-pull-new-branch-name')!
    const generatedBranchName = newBranchNameField.getAttribute('data-generated-branch')
    if (!newBranchNameField.value.length && generatedBranchName) {
      newBranchNameField.value = generatedBranchName
    }
    targetBranchParam.value = newBranchNameField.value
    quickPullParam.value = targetBranchParam.getAttribute('data-default-value') || ''
    submitButton.textContent = submitButton.getAttribute('data-pull-text') || ''
  } else {
    targetBranchParam.value = targetBranchParam.getAttribute('data-default-value') || ''
    quickPullParam.value = ''
    submitButton.textContent = submitButton.getAttribute('data-edit-text') || ''
  }
})

on('click', '.js-quick-pull-choice-option', function (event) {
  const creatingBranch = (event.currentTarget as HTMLInputElement).value === 'quick-pull'
  if (creatingBranch) {
    const newBranchNameField = document.querySelector<HTMLInputElement>('.js-quick-pull-new-branch-name')
    // 0, 0 means its a keyboard event
    if (newBranchNameField && !(event.clientX === 0 && event.clientY === 0)) {
      newBranchNameField.focus()
      newBranchNameField.select()
    }
  }
})

let previousBranchController: AbortController | null = null
let targetBranchInputElement: HTMLInputElement | null = null

async function updateTargetBranch() {
  const proposedBranchInputElement = document.querySelector<HTMLInputElement>('.js-quick-pull-new-branch-name')!
  const proposedRef = proposedBranchInputElement.value
  const generatedBranchName = proposedBranchInputElement.getAttribute('data-generated-branch')!
  const normalizationElement = document.querySelector<HTMLElement>('.js-quick-pull-normalization-info')!

  const formdata = new FormData()
  formdata.append('ref', proposedRef)

  const checkUrl = proposedBranchInputElement.getAttribute('data-check-url')!
  const token = proposedBranchInputElement.parentElement!.querySelector<HTMLInputElement>('.js-data-check-url-csrf')!
  previousBranchController?.abort()
  const {signal} = (previousBranchController = new AbortController())
  try {
    const response = await fetch(checkUrl, {
      mode: 'same-origin',
      method: 'POST',
      body: formdata,
      signal,
      headers: {
        Accept: 'application/json',
        'Scoped-CSRF-Token': token.value,
      },
    })
    if (!response.ok) throw new Error('network error')
    const data = await response.json()

    if (proposedRef !== proposedBranchInputElement.value) {
      return
    }
    const normalizedRef = data['normalized_ref']
    normalizationElement.innerHTML = data['message_html'] == null ? '' : data['message_html']
    if (!normalizedRef) {
      const code = normalizationElement.querySelector<HTMLElement>('code')!
      code.textContent = generatedBranchName
    }

    if (targetBranchInputElement) {
      targetBranchInputElement.value = normalizedRef
    }
  } catch (error) {
    if (proposedRef !== proposedBranchInputElement.value) {
      return
    }
    if (targetBranchInputElement) {
      targetBranchInputElement.value = proposedRef
    }
  }
}

// debounce to reduce number of server requests.
// Not necessary to preserve correctness.
const debouncedUpdateTargetBranch = debounce(updateTargetBranch, 200)

onInput('.js-quick-pull-new-branch-name', function (event) {
  const proposedRef = (event.target as HTMLInputElement).value
  targetBranchInputElement = document.querySelector<HTMLInputElement>('.js-quick-pull-target-branch')!
  targetBranchInputElement.value = proposedRef
  if (proposedRef.length) {
    debouncedUpdateTargetBranch()
  }
})

on('click', '[data-template-button]', function () {
  window.onbeforeunload = null
})
