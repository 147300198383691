import {addThrottledInputEventListener, removeThrottledInputEventListener} from '../throttled-input'
import {getAsyncCodeEditor} from '../code-editor'
import {onFocus} from '@github-ui/onfocus'

async function onFilenameChange(input: HTMLInputElement | HTMLTextAreaElement) {
  const detectUrl = input.getAttribute('data-language-detection-url')
  if (!detectUrl) return

  const editorContainer = document.querySelector<HTMLElement>('.js-code-editor')
  if (!editorContainer) return

  // The filename should be encoded as base64, because git paths
  // can contain values unrepresentable in utf-8
  const encodedValue = btoa(encodeURIComponent(input.value))

  // Creates a URL like `/.../detect_url?filename="input.value_as_base64"
  const url = new URL(detectUrl, window.location.origin)
  const params = new URLSearchParams(url.search.slice(1))
  params.append('filename', encodedValue)
  url.search = params.toString()

  const response = await fetch(url.toString(), {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
    },
  })
  if (!response.ok) {
    const responseError = new Error()
    const statusText = response.statusText ? ` ${response.statusText}` : ''
    responseError.message = `HTTP ${response.status}${statusText}`
    throw responseError
  }
  const data = await response.json()

  // Set the CodeEditor's mode
  const editor = await getAsyncCodeEditor(editorContainer)
  editor.setMode(data.language)
}

// When a user types a filename in for a file we send it back for lang detection
// If we get back a language we set the editor mode
onFocus('.js-detect-filename-language', input => {
  const field = input as HTMLInputElement
  addThrottledInputEventListener(field, onFilenameChange)
  input.addEventListener('blur', () => removeThrottledInputEventListener(field, onFilenameChange), {once: true})
})
