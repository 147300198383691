import CodeMirror from 'codemirror'
import type {Editor} from 'codemirror'
import {insertMdLink, insertMdToken, toggleMdList} from './markdown'

const modifierKey = /Macintosh/.test(navigator.userAgent) ? 'Cmd' : 'Ctrl'
const ITALIC_TOKEN = '_'
const BOLD_TOKEN = '**'
const CODE_TOKEN = '`'
export const DISALLOW_LINKYFY_ON_PASTE = new WeakMap<Editor, boolean>()
export const FOCUS_TRAP_DISABLED = new WeakMap<Editor, boolean>()

export const DefaultKeyMappings = {
  Enter: (cm: Editor) => {
    cm.execCommand('newlineAndIndentContinueMarkdownList')
  },
  Tab: (cm: Editor) => {
    if (FOCUS_TRAP_DISABLED.get(cm)) {
      return CodeMirror.Pass // let this bubble up to the browser
    }

    if (cm.somethingSelected()) {
      cm.indentSelection('add')
      return
    }
    if (cm.getOption('indentWithTabs')) {
      // @ts-expect-error replaceSelection only accepts 2 arguments?
      cm.replaceSelection('\t', 'end', '+input')
    } else {
      cm.execCommand('insertSoftTab')
    }
  },
  'Shift-Tab': (cm: Editor) => {
    if (FOCUS_TRAP_DISABLED.get(cm)) {
      return CodeMirror.Pass // let this bubble up to the browser
    }

    cm.indentSelection('subtract')
  },
  'Cmd-/': 'toggleComment',
  'Ctrl-/': 'toggleComment',
  // These will be one of `Ctrl-*` or `Cmd-*`, depending on userAgent
  [`${modifierKey}-I`]: (cm: Editor) => insertMdToken(cm, ITALIC_TOKEN),
  [`${modifierKey}-B`]: (cm: Editor) => insertMdToken(cm, BOLD_TOKEN),
  [`${modifierKey}-E`]: (cm: Editor) => insertMdToken(cm, CODE_TOKEN),
  [`${modifierKey}-K`]: (cm: Editor) => insertMdLink(cm),
  [`Shift-${modifierKey}-.`]: (cm: Editor) => toggleMdList(cm, '>'), // blockquote
  [`Shift-${modifierKey}-7`]: (cm: Editor) => toggleMdList(cm, 'ol'), // ordered list
  [`Shift-${modifierKey}-8`]: (cm: Editor) => toggleMdList(cm, '-'), // unordered list
  // Supports Cmd+Shift+V (Chrome) / Cmd+Shift+Opt+V (Safari, Firefox and Edge) to mimic paste and match style shortcut on MacOS.
  [`Shift-${modifierKey}-Alt-V`]: (cm: Editor) => {
    DISALLOW_LINKYFY_ON_PASTE.set(cm, true)
    // indicates not to handle the key, and other handlers (or the default behavior) should be given a turn.
    return CodeMirror.Pass
  },
  [`Shift-${modifierKey}-V`]: (cm: Editor) => {
    DISALLOW_LINKYFY_ON_PASTE.set(cm, true)
    // indicates not to handle the key, and other handlers (or the default behavior) should be given a turn.
    return CodeMirror.Pass
  },
}

export const FocusTrapKeybindings = {
  [`Shift-Ctrl-M`]: (cm: Editor) => {
    // toggles focus trap on/off
    const focusTrapDisabled = FOCUS_TRAP_DISABLED.get(cm) || false

    FOCUS_TRAP_DISABLED.set(cm, !focusTrapDisabled)
  },
}

export function setupEditorFocusTrap(editor: Editor) {
  // turn off default tab bindings - we dont use these anyway since we have custom behavior above
  editor.setOption('extraKeys', {Tab: false, 'Shift-Tab': false})
  editor.addKeyMap(FocusTrapKeybindings)
}
